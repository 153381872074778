import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';
import Loading from './Loading';  // Make sure this path matches your project structure

const App = React.lazy(() => import('./App'));
const Privacy = React.lazy(() => import('./Privacy'));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/datenschutz" element={<Privacy />} />
        </Routes>
      </Suspense>
    </Router>
  </React.StrictMode>
);